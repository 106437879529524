import { Component, Vue } from "vue-property-decorator";
import { ValidateIf, IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { AccountModel } from "@/models";
import { Common } from "../Common";

export class AccountForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) acc = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) pwd = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) name = '';
    @IsDefined() @MaxLength(100, { message: "最多輸入100字" }) note = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) identity = '1';
    cid = '';
    @ValidateIf(o => o.identity === '2' || o.identity === '3') @IsDefined() @IsNotEmpty({ message: "必填" }) sid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = 1;
    token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminAccountAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminAccountAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private accForm = new AccountForm();
    private cityList: object = {};
    private schoolList: object = {};
    private errorMsg = "";
    private openLB = false;
    private token = window.localStorage.getItem('adminToken') as string;

    private errNo!: number;

    private errorMsgMap: { [key: string]: string } = {
        acc: '',
        pwd: '',
        name: '',
        note: '',
        identity: '',
        sid: '',
        active: '',
    };

    public async created() {
        this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
    }

    //取得 該身份&縣市下的分校
    private async getSchool() {
        this.accForm.sid = '';
        const cid = this.accForm.cid;
        const identity = this.accForm.identity;
        if (cid !== '') {
            const item: { [key: string]: string } = {
                cid: cid,
                category: identity,
                token: this.token,
            }
            const data = await AccountModel.getIdentitySchool(item) as response;
            Common.checkToken(data.ERR_CODE as number);
            this.schoolList = data.list as object;
        }
    }

    private validateAndSubmit() {
        validate(this.accForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        acc: '',
                        pwd: '',
                        name: '',
                        note: '',
                        identity: '',
                        sid: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = JSON.parse(JSON.stringify(this.accForm));

                    this.errNo = await AccountModel.add(item);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/account");
        }
    }

}
